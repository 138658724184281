import dayjs from 'dayjs'

/**Start and end dates of current year edition (Default to start and end of july) */
export const SUBSCRIPTION_SETTINGS = {
    startDate: dayjs().month(7).startOf('month'),
    endDate: dayjs().month(7).endOf('month'),
}

/**True if the current date is between start and end dates */
export const IS_SUBSCRIPTION_AVAILABLE = dayjs().isAfter(SUBSCRIPTION_SETTINGS.startDate) && dayjs().isBefore(SUBSCRIPTION_SETTINGS.endDate)

/**Folder to use in carousel of index page */
export const CAROUSEL_PHOTOS_FOLDER = 'winning2021'

/**Google maps API key */
export const MAP_API_KEY = 'AIzaSyBadL0LOZUJeIB79nNsZsnfIw5dInO7PMA'

/**Max file size in MB of any file uploaded. Need to be changed in storage rules too */
export const MAX_FILE_SIZE = 30

/**List of contest steps */
export const CONTEST_STEPS = [
    {
        day: '01',
        month: 'Agosto',
        description: 'Início das inscrições'
    },
    {
        day: '31',
        month: 'Agosto',
        description: 'Data final das inscrições'
    },
    {
        day: '01 à 13',
        month: 'Setembro',
        description: 'Julgamento das fotos'
    },
    {
        day: '14',
        month: 'Setembro',
        description: 'Divulgação dos vencedores'
    }
]

/**Snackbar variants */
export const SNACKBAR_VARIANTS = {
    success: 'success',
    warning: 'warning',
    info: 'info',
    error: 'error'
}