import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
    const { site } = useStaticQuery(graphql`
        query useSiteMetadata {
            site {
                siteMetadata {
                    title
                    description
                    image,
                    url
                }
            }
        }
    `)
    return site.siteMetadata
}

export default useSiteMetadata