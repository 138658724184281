import React from 'react'
import styles from './styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useIcon from '@hooks/useIcon'
import { ButtonLink } from '@components/atoms'
import { globalStyles } from '@styles'

const PageHeader = props => {
    const { icon, children, description, button, maxWidth='sm' } = props
    const iconImg = useIcon(icon.name)

    return (
        <Box sx={ styles.background }>
            <Container maxWidth={ maxWidth } align='center'>
                <Grid container spacing={ 4 } justifyContent='center' alignItems='center' direction="row">
                    <Grid item xs={ 3 }>
                        <Divider/>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Box sx={ styles.icon }>
                            <GatsbyImage loading='eager' image={ getImage(iconImg) } alt={ icon.alt } style={{ width: '100%' }}/>
                        </Box>
                    </Grid>
                    <Grid item xs={ 3 }>
                        <Divider/>
                    </Grid>
                </Grid>
                <Typography component='h1' sx={{ ...globalStyles.subtitle('h4'), mt: 2 }} color='primary'>{ children }</Typography>
                { button && <ButtonLink color='primary' to={ button.path } variant='outlined' size='large' sx={{ marginTop: 4 }}>{ button.label }</ButtonLink> }
                { description && <Typography sx={ styles.description } color='secondary' component='h5'>{ description }</Typography> }
            </Container>  
        </Box>  
    )
}
export default PageHeader