import React from 'react'
import PlaceIcon from '@mui/icons-material/PlaceRounded'
import Typography from '@mui/material/Typography'
import MainIcon from '@mui/icons-material/MailRounded'
import PhoneIcon from '@mui/icons-material/PhoneRounded'
import Divider from '@mui/material/Divider'
import styles from './styles'

const Footer = () => (
    <footer style={ styles.background }>
        <div style={ styles.container }>
            <Divider/>
            <div style={ styles.infos } itemScope itemType="http://schema.org/Organization">
                <div style={ styles.infoItem }>
                    <PlaceIcon color='primary' style={ styles.icon } />
                    <Typography variant="body2" color="primary" align='left' itemProp='adress'>
                        Rua Rui Barbosa, 1150 - Centro, Turvo - SC, 88930-000
                    </Typography>
                </div>
                <div style={ styles.infoItem }>
                    <MainIcon color='primary' style={ styles.icon }/>
                    <Typography variant="body2" color="primary" itemProp='email'>
                        foconocooperativismo@credisulca.coop.br
                    </Typography>
                </div>
                <div style={ styles.infoItem } >
                    <PhoneIcon color='primary' style={ styles.icon }/>
                    <Typography variant="body2" color="primary" itemProp='telephone'>(48) 3525-8250</Typography>
                </div>
            </div>
            <div style={ styles.infos }>
                <Typography variant="body2" color="textSecondary">
                    Copyright &copy; { new Date().getFullYear() } <span itemProp='legalName'>Sicoob Credisulca</span>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Feito com ❤ pelo setor de Desenvolvimento de Software do Sicoob Credisulca
                </Typography>
            </div>
        </div>
    </footer>
)

export default Footer