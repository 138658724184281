import React from 'react'
import Container from '@mui/material/Container'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import styles from './styles'
import { Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from "@mui/material/styles"

const Tabs = props => {
    const { tabs, selected, onChange } = props
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const Label = ({ label, caption }) => (
        <div>
            { caption && <Typography variant='caption'>{ caption }</Typography> }
            <Typography variant='body2' sx={ styles.tab }>{ label }</Typography>
        </div>
    )

    return (
        <Container maxWidth='md'>
            <Box sx={ styles.box }>
                <MuiTabs centered={ !isMobile } value={ selected } onChange={ (_, val) => onChange(val) } sx={ styles.tabs }
                    variant={ isMobile ? 'scrollable' : 'standard' } scrollButtons allowScrollButtonsMobile
                >
                    { tabs.map(tab => <Tab key={ tab.label } label={ <Label { ...tab }/> }/>) }
                </MuiTabs>
            </Box>
        </Container>
    )
}

export default Tabs