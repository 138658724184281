import { useStaticQuery, graphql } from "gatsby"

/**
 * Make a query in images folder, and returns the images selected by user
 * 
 * ---
 * 
 * @param { (string | string[]) } name - A single file name or an array of file names to return the images
 * @returns { * } The query result file
 */
const useImage = name => {
    const data = useStaticQuery(graphql`
        query useImage {
            camera: file(relativePath: {eq: "images/404.jpg"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            logo_dark: file(relativePath: {eq: "images/logo_dark.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            logo_light: file(relativePath: {eq: "images/logo_light.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            business_logo: file(relativePath: {eq: "images/business_logo.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            subscription_banner: file(relativePath: {eq: "images/subscription_banner.jpg"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_question: file(relativePath: {eq: "images/illustrations/question.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_done_checking: file(relativePath: {eq: "images/illustrations/done_checking.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_success: file(relativePath: {eq: "images/illustrations/success.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_reading: file(relativePath: {eq: "images/illustrations/reading.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_personal_information: file(relativePath: {eq: "images/illustrations/personal_information.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_accept: file(relativePath: {eq: "images/illustrations/accept.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_choice: file(relativePath: {eq: "images/illustrations/choice.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_photographer: file(relativePath: {eq: "images/illustrations/photographer.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_people: file(relativePath: {eq: "images/illustrations/people.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_photo: file(relativePath: {eq: "images/illustrations/photo.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            ill_finish: file(relativePath: {eq: "images/illustrations/finish.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    `)

    if (name) {
        if (typeof name === 'string') return data[name]
        else return Object.keys(data).filter(el => name.includes(el)).map(el => data[el])
    }
    return data
}

export default useImage