import React from 'react'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { navigate } from "gatsby"
import { Match } from '@reach/router'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styles from './styles'
import { SocialNetworks } from '@components/molecules'
import ROUTES from '@constants/routes'
import { IS_SUBSCRIPTION_AVAILABLE } from '@constants/general'

const DrawerContent = props => {
    const { onClose } = props

    const handleClickItem = path => {
        onClose()
        navigate(path)
    }

    return (
        <div style={ styles.drawerBackground } role="menu">
            <div style={ styles.drawerTop }>
                <SocialNetworks color='secondary'/>
                <IconButton aria-label='Botão Fechar Menu Lateral' color='secondary' onClick={ onClose }>
                    <CloseIcon/>
                </IconButton>
            </div>
            <List style={{ flexGrow: 1 }}>
                { Object.values(ROUTES).filter(el => !el.hidden).map(({ path, title }) => (
                    <Match path={ path } key={ path } >
                        { ({ match }) => (
                            <ListItem button onClick={ () => handleClickItem(path) }>
                                <Typography component="li" variant='h6' gutterBottom style={ match ? undefined : styles.drawerListItem }>
                                    { title }
                                </Typography>
                            </ListItem>
                        )}
                    </Match>
                ))}
            </List>
            <Match path={ ROUTES.subscription.path } >
                { ({ match }) => !match && (
                    <Button fullWidth variant='outlined' color='secondary' sx={ styles.drawerButtonSub }
                        onClick={ () => navigate(IS_SUBSCRIPTION_AVAILABLE ? ROUTES.subscription.path : ROUTES.about.path + '#contador') }
                    >
                        Participar
                    </Button>
                )}
            </Match>
        </div>
    )
}

export default DrawerContent