import React from 'react'
import TextField from '@mui/material/TextField'
import MaskedInput from 'react-text-mask'

const TextMaskInput = React.forwardRef(function Custom(props, ref) {
    return <MaskedInput { ...props } ref={ val => { ref(val ? val.inputElement : null) }} placeholderChar={ '\u2000' } />
})

/**Text input with optional mask, just pass a mask array as props */
const MaskedTextField = props => (
    <TextField { ...props } mask={ undefined } InputProps={{ ...props.InputProps, inputComponent: props.mask ? TextMaskInput : undefined, inputProps: { mask: props.mask } }}

    />
)

export default MaskedTextField