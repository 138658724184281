const styles = {
    background: {
        display: 'flex',
        alignItems: 'center',
        height: {
            xs: '60vh',
            md:'70vh',
        }
    },
    icon: {
        width: 70
    },
    description: {
        marginTop: 3,
        opacity: .8
    }
}

export default styles