const styles = {
    background: {
        display: 'flex',
        alignItems: 'center',
        padding: { 
            xs: 3,
            md: 0
        }
    },
    textArea: {
        px: {
            xs: 0,
            md: 6,
        },
        pb: {
            xs: 3,
            md: 0
        }
    }
}

export default styles