import { useStaticQuery, graphql } from "gatsby"

const useFolderImages = folder => {
    const data = useStaticQuery(graphql`
        query {
            winning2018: allFile(filter: { relativeDirectory: {eq: "images/winningPhotos/2018"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
            winning2019: allFile(filter: { relativeDirectory: {eq: "images/winningPhotos/2019"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
            winning2020: allFile(filter: { relativeDirectory: {eq: "images/winningPhotos/2020"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
            winning2021: allFile(filter: { relativeDirectory: {eq: "images/winningPhotos/2021"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
            jurors: allFile(filter: { relativeDirectory: {eq: "images/jurors"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
            agencies: allFile(filter: { relativeDirectory: {eq: "images/agencies"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
            creators: allFile(filter: { relativeDirectory: {eq: "images/creators"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
            awards: allFile(filter: { relativeDirectory: {eq: "images/awards"} }) {
                edges {
                    node {
                        name
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        } 
                    }
                }
            }
        }
    `)
    
    return folder ? data[folder].edges.map(el => el.node) : data
}

export default useFolderImages