const styles = {
    background: {
        marginTop: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        width: '90vw'
    },
    icon: {
        marginRight: 10
    },
    infos: {
        paddingTop: 15,
        paddingBottom: 12,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    infoItem: {
        display: 'flex',
        marginTop: 15,
        marginBottom: 12,
        flexDirection: 'row',
        alignItems: 'center',
    }
}

export default styles