import React from 'react'
import IconButton from '@mui/material/IconButton'
import { SOCIAL_NETWORKS } from '@constants/links'

const SocialNetworks = props => {
    const { color='primary' } = props
    
    return (
        <div>
            { SOCIAL_NETWORKS.map(({ aria, href, Icon }) => (
                <IconButton aria-label={ aria } size='small' target="_blank" rel="noopener" color={ color } href={ href } key={ aria } style={{ padding: 5 }}>
                    <Icon/>
                </IconButton>
            ))}
        </div>
    )
}
export default SocialNetworks