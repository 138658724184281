const styles = {
    background: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh'
    },
    logo: {
        width: { 
            md: '20vw',
            xs: '40vw'
        },
        marginBottom: 4,
    }
}

export default styles