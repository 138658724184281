import React from "react"
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from "@hooks"

const Seo = props => {
    const { description, meta=[], title } = props
    const siteMetadata = useSiteMetadata()
    const metaDescription = description || siteMetadata.description

    return (
        <Helmet htmlAttributes={{ lang: 'pt-br' }} defaultTitle="Participe" title={ title }
            titleTemplate={`%s | ${ siteMetadata.title }`}
            meta={[
                { name: `description`, content: metaDescription },
                { name: `image`, content: siteMetadata.image },
                { property: `og:title`, content: siteMetadata.title },
                { property: `og:description`, content: metaDescription },
                { property: `og:type`, content: `website` },
                { property: `og:image`, content: siteMetadata.image },
                { property: `og:url`, content: siteMetadata.url },
            ].concat(meta) }
        />
    )
}

export default Seo
