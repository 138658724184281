import React, { useState } from 'react'
import styles from './styles'
import ROUTES from '@constants/routes'
import { ButtonLink } from '@components/atoms'
import { Match } from "@reach/router"
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/MenuRounded'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useImage from '@hooks/useImage'
import { SocialNetworks } from '@components/molecules'
import DrawerContent from './DrawerContent'
import useIcon from '@hooks/useIcon'
import { IS_SUBSCRIPTION_AVAILABLE } from '@constants/general'

const Web = props => {
    const { scrolled } = props
    const routesArr = Object.values(ROUTES)
    const logos = useImage(['logo_dark', 'logo_light'])

    return (
        <Box sx={ styles.toolbar }>
            <SocialNetworks color={ scrolled ? 'primary' : 'inherit' }/>
            <div style={{ marginLeft: 40 }}>
                { routesArr.slice(0, 3).map(({ path, title }) => (
                    <Match path={ path } key={ path }>
                        { ({ match }) => (
                            <ButtonLink to={ path } color={ scrolled ? 'primary' : 'inherit' } sx={ styles.toolbarButton(match) }>
                                { title }
                            </ButtonLink>
                        )}
                    </Match>
                ))}
            </div>
            <div style={ styles.logoBackground }>
                <Link to={ ROUTES.index.path }>
                    <GatsbyImage loading='eager' image={ getImage(scrolled ? logos[0] : logos[1]) } alt="Logo Concurso de Fotografia"/>
                </Link>
            </div>
            <div>
                { routesArr.slice(3, 6).map(({ path, title }) => (
                    <Match path={ path } key={ path }>
                        { ({ match }) => (
                            <ButtonLink to={ path } color={ scrolled ? 'primary' : 'inherit' } sx={ styles.toolbarButton(match) }>
                                { title }
                            </ButtonLink>
                        )}
                    </Match>
                ))}
            </div>
            <Match path={ ROUTES.subscription.path } >
                { ({ match }) => (
                    <Box sx={ styles.subscriptionButton(match) }>
                        <ButtonLink to={ IS_SUBSCRIPTION_AVAILABLE ? ROUTES.subscription.path : ROUTES.about.path + '#contador' } variant='outlined'
                            color={ scrolled ? 'primary' : 'inherit' }
                        >
                            Participar
                        </ButtonLink>
                    </Box>
                )}
            </Match>
        </Box>
    )
} 

const Mobile = props => {
    const { scrolled } = props
    const [drawerOpen, setDrawerOpen] = useState(false)
    const icons = useIcon(['logo_dark', 'logo_light'])

    const handleChangeDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <>
            <Box sx={{ ...styles.toolbar, justifyContent: 'space-between', px: 1.4 }}>
                <div style={ styles.iconBackground }>
                    <Link to={ ROUTES.index.path }>
                        <GatsbyImage loading='eager' image={ getImage(scrolled ? icons[0] : icons[1]) } alt="Icone Concurso de Fotografia"/>
                    </Link>
                </div>
                <div style={{ marginRight: 5 }}>
                    <IconButton aria-label='Botão abrir menu lateral' color={ scrolled ? 'primary' : 'inherit' } onClick={ handleChangeDrawer }>
                        <MenuIcon/>
                    </IconButton>
                </div>
            </Box>
            <Drawer open={ drawerOpen } onClose={ handleChangeDrawer } anchor="right">
                <DrawerContent onClose={ handleChangeDrawer }/>
            </Drawer>
        </>
    )
}

const Toolbar = {
    Web,
    Mobile
}

export default Toolbar