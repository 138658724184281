const styles = {
    background: {
        my: { 
            md: 5,
            xs: 3
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: { 
            md: 6,
            xs: 3
        }
    },
    img: {
        mb: 2,
        width: { 
            md: '30vw',
            sm: '45vw',
            xs: '60vw'
        }
    }
}

export default styles