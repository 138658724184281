import React from 'react'
import Typography from '@mui/material/Typography'
import useImage from '@hooks/useImage'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from '@mui/material/Box'
import styles from './styles'

const Feedback = props => {
    const { title='Em breve!', description, image='ill_question', sx } = props
    const img = useImage(image)

    return (
        <Box sx={{ ...styles.background, ...sx }}>
            <Box sx={ styles.img }>
                <GatsbyImage image={ getImage(img) } alt='Ilustração'/>
            </Box>
            <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold' }}>{ title }</Typography>
            <Typography align='center' variant='h6' color='secondary' sx={{ fontWeight: 400 }}>
                { description }
            </Typography>
        </Box>
    )
}

export default Feedback