const globalStyles = {
    subtitle: variant => ({
        fontWeight: 'bold',
        fontSize: {
            xs: 28,
            md: `${ variant }.fontSize`
        }
    }),
}

export default globalStyles