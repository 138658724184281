import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import styles from './styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useFolderImages from '@hooks/useFolderImages'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTheme } from "@mui/material/styles"
import Box from '@mui/material/Box'
import { globalStyles } from '@styles'

const ImageWithText = props => {
    const { image, title, subtitle, children, reverse, maxHeight='100vh', sx } = props
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const imageData = useFolderImages(image.split('.')[0]).find(({ name }) => name === image.split('.')[1])

    return (
        <Box sx={{ ...styles.background, ...(sx || {}) }}>
            <Grid container alignItems="center" justify="space-between" flexDirection={ reverse ? 'row-reverse' : 'row' }>
                <Grid item xs={ 12 } md={ 6 }>
                    <Box sx={ styles.textArea }>
                        <Typography gutterBottom={ !subtitle } color='primary' variant='h3' component='h3' sx={ globalStyles.subtitle('h3') }>
                            { title }
                        </Typography>
                        { subtitle && <Typography gutterBottom sx={{ opacity: .6 }} variant='h6' component='h3'>{ subtitle }</Typography> }
                        <Typography color='secondary' align='left' style={{ textAlign: 'justify' }}>
                            { children }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <GatsbyImage loading='eager' image={ getImage(imageData) } alt={ imageData.name } style={{ width: '100%', maxHeight: isMobile ? '40vh' : maxHeight }}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ImageWithText