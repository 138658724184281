import { useStaticQuery, graphql } from "gatsby"

/**
 * Make a query in icons folder, and returns the images selected by user
 * 
 * ---
 * 
 * @param { (string | string[]) } name - A single file name or an array of file names to return the images
 * @returns { * } The query result file
 */
const useIcon = name => {
    const data = useStaticQuery(graphql`
        query useIcon {
            logo_dark: file(relativePath: {eq: "icons/logo_dark.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            logo_light: file(relativePath: {eq: "icons/logo_light.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            trophy: file(relativePath: {eq: "icons/trophy.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            clock: file(relativePath: {eq: "icons/clock.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            collaboration: file(relativePath: {eq: "icons/collaboration.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            gift: file(relativePath: {eq: "icons/gift.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            help: file(relativePath: {eq: "icons/help.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            libra: file(relativePath: {eq: "icons/libra.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    `)

    if (name) {
        if (typeof name === 'string') return data[name]
        else return Object.keys(data).filter(el => name.includes(el)).map(el => data[el])
    }
    return data
}

export default useIcon