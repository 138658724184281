const styles = {
    box: {
        borderBottom: 1,
        borderColor: 'divider'
    },
    tab: {
        fontSize: {
            md: 18,
            xs: 16
        },
        textTransform: 'none',
        px: 1,
        mb: -.3
    },
    tabs: {
        '& .MuiTabs-indicator': {
            height: 3,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
        }
    }
}

export default styles