const styles = {
    appbar: {
        backgroundColor: 'transparent',
        minHeight: {
            sm: 60,
            md: 86
        },
        py: {
            sm: 1
        }
    },
    transition: opacity => ({
        position: 'absolute',
        top: 0, right: 0, left: 0, bottom: 0,
        background: `linear-gradient(rgba(0,0,0,${ .5 - (opacity / 2) }), transparent)`,
        backgroundColor: `rgba(255,255, 255, ${ opacity })`
    }),
    toolbar: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    toolbarButton: match => ({
        backgroundColor: 'transparent',
        fontWeight: match ? 700 : 400,
        transition: 'opacity 0.5s',
        opacity: match ? .9 : .7,
        whiteSpace: 'nowrap',
        '&:hover': {
            opacity: 0.9,
        },
    }),
    logoBackground: {
        paddingLeft: 10,
        paddingRight: 10,
        width: 140,
        minWidth: 140,
    },
    iconBackground: {
        padding: 15,
        width: 60,
    },
    subscriptionButton: match => ({
        visibility: match ? 'hidden' : 'visible',
    }),
    drawerBackground: {
        width: '75vw',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 15
    },
    drawerTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    drawerButtonSub: {
        paddingTop: 1,
        paddingBottom: 1
    },
    drawerListItem: {
        opacity: 0.6,
    }
}

export default styles