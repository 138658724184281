import React from "react"
import { Appbar, Footer, Seo } from './components'
import styles from './styles'

const Layout = props => {
    const { children, seo, transparentAppbar } = props

    return (
        <div className={ styles.background }>
            <Seo { ...seo }/>
            <Appbar transparentAppbar={ transparentAppbar }/>
            <main>
                { children }
            </main>
            <Footer/>
        </div>
    )
}

export default Layout