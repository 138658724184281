const styles = {
    background: small => ({
        position: 'absolute',
        left: {
            md: small ? 17 : 30,
            xs: 14
        },
        bottom: {
            md: small ? 10 : 30,
            xs: 10
        },
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center'
    }),
    divider: small => ({
        mx: {
            md: small ? 2 : 3,
            xs: 1.5
        },
        height: {
            md: small ? 40 : 60,
            xs: 30
        },
        width: '1px',
        backgroundColor: '#fff',
    }),
    title: small => ({
        mb: small ? -0.5 : 0,
        fontSize: {
            xs: 20,
            md: small ? 22 : 'h4.fontSize'
        }
    }),
    position: small => ({
        fontSize: {
            xs: 30,
            md: small ? 32 : 'h2.fontSize'
        }
    }),
    author: small => ({
        opacity: .7,
        fontSize: {
            xs: 16,
            md: small ? 15 : 'body1.fontSize'
        }
    })
}

export default styles