const ROUTES = {
    index:  { id: 'index', path: '/', title: 'Início' },
    photos: { id: 'photos', path: '/fotos-premiadas', title: 'Fotos Premiadas' },
    jurors: { id: 'jurors', path: '/jurados', title: 'Jurados' },
    about:  { id: 'about', path: '/sobre', title: 'Sobre' },
    exhibition: { id: 'exhibition', path: '/exibicao', title: 'Exposição das obras' },
    awards: { id: 'awards', path: '/premios', title: 'Prêmios' },
    subscription: { id: 'subscription', path: '/inscricao', title: 'Inscrição', hidden: true },
    rules: { id: 'rules', path: '/edital', title: 'Edital', hidden: true },
}

export default ROUTES