import React from 'react'
import styles from './styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'

const PhotoTitle = props =>  {
    const { title, position, author, visible, small } = props
    
    return (
        <Grow in={ visible } timeout={{ enter: 1000, exit: 500 }}>
            <Box sx={ styles.background(small) } >
                <Typography component="h5" color='white' sx={ styles.position(small) }>
                    { position }º
                </Typography>
                <Box sx={ styles.divider(small) }/>
                <div>
                    <Typography component="h4" color='white' sx={ styles.title(small) }>
                        { title }
                    </Typography>
                    <Typography component="h4" color='white' sx={ styles.author(small) }>
                        { author }
                    </Typography>
                </div>
            </Box>
        </Grow >  
    )
}
            
export default PhotoTitle