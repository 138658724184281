import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'
import styles from './styles'
import Toolbar from './Toolbar'
import MuiToobar from '@mui/material/Toolbar'

const HideOnScroll = props => {
    const { children } = props
    const scrolled = useScrollTrigger()

    return (
        <Slide appear={ false } direction="down" in={ !scrolled }>
            { children }
        </Slide>
    )
}

const TransitionEffect = ({ scrolled }) => {
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        const id = setInterval(() => setOpacity(prev => {
            const newVal = prev + (scrolled ? .05 : -.05)
            
            return newVal > 1 ? 1 : newVal < 0 ? 0 : newVal
        }), 20)
        return () => clearInterval(id)
    }, [scrolled])

    return <div style={ styles.transition(opacity) }/>
}

const Appbar = props => {
    const { transparentAppbar } = props 
    const scrolled = useScrollTrigger({ disableHysteresis: true, threshold: 20 })

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar elevation={ scrolled ? 1 : 0 } sx={ styles.appbar }>
                    <TransitionEffect scrolled={ !transparentAppbar || scrolled }/>
                    <Hidden mdDown>
                        <Toolbar.Web scrolled={ !transparentAppbar || scrolled }/>
                    </Hidden>
                    <Hidden mdUp>
                        <Toolbar.Mobile scrolled={ !transparentAppbar || scrolled }/>
                    </Hidden>
                </AppBar>
            </HideOnScroll>
            { !transparentAppbar && <MuiToobar/> }
        </React.Fragment>
    )
}

export default Appbar