import React from 'react'
import Typography from '@mui/material/Typography'
import styles from './styles'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from '@mui/material/Container'
import useImage from '@hooks/useImage'
import Box from '@mui/material/Box'

const BusinessDescription = props => {
    const { style } = props
    const imageData = useImage('business_logo')

    return ( 
        <Container maxWidth='md' sx={{ ...styles.background, ...(style || {}) }} itemScope itemType="http://schema.org/Organization">
            <Box sx={ styles.logo } itemProp='logo'>
                <GatsbyImage image={ getImage(imageData) } alt='Logo Sicoob Credisulca' />
            </Box>
            <Typography color='secondary' align='center' itemProp='description' style={{ width: '100%', textAlign: 'center' }}>
                Somos uma instituição financeira cooperativista que tem como propósito a justiça financeira. Somos
                feitos de valores e contribuímos para o desenvolvimento da nossa comunidade. Temos todos os benefícios
                de um banco, com as vantagens do cooperativismo.
            </Typography>
        </Container>
    )
}

export default BusinessDescription